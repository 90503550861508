import secrets from '../../secrets.json';

export const environment = {
  production: false,
  demo: true,
  debug: true,
  app: 'skalo-doc',
  hotjar: '3409351', // dev key
  stage: 'demo',
  cognitoTokenURL:
    'https://skalo-demo.auth.us-east-2.amazoncognito.com/oauth2/token',

  loginURL:
    'https://skalo-demo.auth.us-east-2.amazoncognito.com/login?client_id=7uv0d8hlmov5l7q16nm4ll3uso&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://demo.doc.skalo.co/callback',
  logout:
    'https://skalo-demo.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7uv0d8hlmov5l7q16nm4ll3uso&redirect_uri=https://demo.doc.skalo.co/callback',
  redirectUrl: 'https://demo.doc.skalo.co/callback',
  registroUrl: 'https://demo.usuario.skalo.co/registro',

  // loginURL: 'https://skalo-demo.auth.us-east-2.amazoncognito.com/login?client_id=7uv0d8hlmov5l7q16nm4ll3uso&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:8100/callback',
  // logout: 'https://skalo-demo.auth.us-east-2.amazoncognito.com/logout?response_type=code&client_id=7uv0d8hlmov5l7q16nm4ll3uso&redirect_uri=http://localhost:8100/callback',
  // redirectUrl: 'http://localhost:8100/callback',

  S3linkServidor: 'https://skalo-priv-demo.s3.us-east-2.amazonaws.com',
  S3accessKey: secrets.demo.S3AccessKeyDev,
  S3accessSecret: secrets.demo.S3AccessSecretDev,
  S3region: 'us-east-2',
  S3bucket: 'skalo-priv-demo',
  sso_api_username: secrets.demo.SsoApiUsername,
  sso_api_pwd: secrets.demo.SsoApiPassword,
  statsUrl:
    'https://skalo-dev.auth.us-east-2.amazoncognito.com/login?client_id=12253gb5cciahtkfds7tnnn1n6&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://estadisticas.skalo.co/login',
  statsUrlDocente:
    'https://skalo-prod.auth.us-east-2.amazoncognito.com/login?client_id=2tq7nv5opamvb014gel3ckh7ov&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://estadisticas.skalo.co/login',
};
